<template>
  <div class="search-dropdown">
    <div
      class="search-content-item"
      v-for="(item, index) in searchItems"
      :key="index"
      @click="searchCategory(item)"
    >
      <p>{{ item.title.default === "Home" ? "All" : item.title.default }}</p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    menuItems: {
      type: Array
    }
  },
  data() {
    return {
      searchItems: []
    };
  },
  methods: {
    searchCategory(val) {
      // console.log("displayCategory", val.title.default);
      let sentCat = val.title.default === "Home" ? [] : val.parameters.category;
      let catObj = {
        disCat: val.title.default,
        apiCat: sentCat
      };
      this.$emit("searchCategory", catObj);
    },
    declareSearchField() {
      this.searchItems = this.menuItems.filter(el => {
        return el.isRequiredLogin === false;
      });
      // console.log("searchItems", this.searchItems);
    }
  },
  mounted() {
    this.declareSearchField();
  }
};
</script>

<style lang="scss">
@import "@/sass/_variables.scss";
@import "./SearchDropdown.scss"
</style>
